<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="onPressUpdateRider('riderData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Шинэ rider үүсгэх'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
              <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Нэр" prop="Username">
                        <el-input v-model="riderData.Username" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Майл" prop="email">
                      <el-input v-model="riderData.email" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="nickname" prop="nickname">
                        <el-input v-model="riderData.nickname" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="Утас" prop="phone_number">
                          <el-input v-model="riderData.phone_number" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Үүссэн огноо">
                          <el-input placeholder="Дижитал аггрегатор" disabled type="text" :value='riderData.UserCreateDate'></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                      <el-form-item label="Сүүлд өөрчилсөн" prop="phone">
                          <el-input v-model="riderData.UserLastModifiedDate" disabled placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Төрсөн өдөр">
                          <div class="block">
                          <el-date-picker
                            value-format="yyyy-MM-dd"
                            v-model="riderData.birthdate"
                            type="date"
                            placeholder="Төрсөн огноо">
                          </el-date-picker>
                          </div>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Регистрийн дугаар">
                          <el-input v-model="riderData.regNo"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <el-form-item label="Нууц үг">
                          <el-button type="primary" @click="changePasswordDialog = true" :disabled="riderData.UserStatus === 'CONFIRMED' ? false : true" plain>Нууц үг солих</el-button>
                        </el-form-item>
                      </el-col>
                    </el-row>
                </div>
                </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="5">
              </el-col>
              <el-col :span="20" class="text-right">
                <!-- <el-button type="danger" @click="deleteAccount = true" style="float:left">Бүртгэл устгах</el-button> -->
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onPressUpdateRider()
                ">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Change password dialog -->
    <el-dialog
      title="Нууц үг солих"
      :visible.sync="changePasswordDialog"
      width="30%">
      <p>Шинэ нууц үгээ оруулна уу!</p>
      <el-popover
    placement="top-start"
    title="Анхаар!!!"
    width="180"
    trigger="hover"
    content="Нууц үг хамгийн багадаа 8 оронтой байх ёстой!">
        <el-input slot="reference" placeholder="Шинэ нууц үг" v-model="changePassword" show-password></el-input>
  </el-popover>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePasswordDialog = false">Болих</el-button>
        <el-button type="success" @click="changePass" :disabled="changePassword.length < 8 ? true : false">Хадгалах</el-button>
      </span>
    </el-dialog>
    <!-- Delete account dialog-->
    <!-- <el-dialog
      title="Бүртгэл устгах"
      :visible.sync="deleteAccount"
      width="30%">
      <span>{{riderData.Username }} Нэртэй account-ийг устгахын тулд delete гэж бичнэ үү!</span>
      <el-input placeholder="delete гэж бичнэ үү!" v-model="deleteWrite"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteAccount = false">Болих</el-button>
        <el-button type="danger" @click="removeUser" :disabled="deleteWrite === 'delete' ? false : true">Устгах</el-button>
      </span>
    </el-dialog> -->

    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/supplier-admin-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
import { getUser } from '../../../utils/auth'
export default {
  components: {
    customHeader: customHeader
  },

  data () {
    return {
      changePasswordDialog: false,
      deleteWrite: '',
      changePassword: '',
      deleteAccount: false,
      userName: '',
      supplierLoading: false,
      loading: false,
      confirmDeleteSupplierDialog: false,
      confirmLeaveForm: false,
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      riderData: []
    }
  },

  created () {
    if (this.$route.params.user_name) {
      this.getOneRider(this.$route.params.user_name)
    }
  },
  mounted () {
    getUser().then((user) => {
      this.userName = user.username
    })
  },
  methods: {
    changePass () {
      const body = {
        userName: this.riderData.Username,
        password: this.changePassword
      }
      const query = '?user_name=' + this.riderData.Username
      services.changePasswordRider(query, body).then(el => {
        if (el.status === 'success') {
          this.alertReporter('Амжилттай', 'Нууц үг амжилттай солигдлоо', 'success')
          this.changePassword = ''
          this.changePasswordDialog = false
        } else {
          this.alertReporter('Алдаа', el.message, 'error')
        }
      })
    },
    removeUser () {
      const query = '?user_name=' + this.riderData.Username
      services.removeUser(query).then(el => {
        if (el.status === 'success') {
          this.alertReporter('Амжилттай', 'Амжилттай устгалаа', 'success')
          this.deleteAccount = false
        } else {
          this.alertReporter('Алдаа', el.message, 'error')
        }
      })
    },
    confirmSignUp (user) {
      services.confirmSignUp({ user_name: user }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', 'Амжилттай баталгаажсан.', 'success')
          this.getOneRider(this.$route.params.user_name)
        } else {
          this.alertReporter('Амжилттай', response.message, 'error')
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },

    onPressUpdateRider () {
      const body = {
        UserName: this.riderData.Username,
        birthdate: this.riderData.birthdate,
        nickname: this.riderData.nickname,
        phone_number: this.riderData.phone_number,
        email: this.riderData.email,
        regNo: this.riderData.regNo,
        status: 'active'
      }
      services.updateRider(body).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
          this.riderData = response.data
          this.$router.push({ name: 'riderList' })
        } else if (response.status === 'error') {
          this.alertReporter('Амжилтгүй', response.message, 'error')
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    getOneRider (userName) {
      services.getRider(userName).then(response => {
        if (response.status === 'success') {
          this.riderData = response.data
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    }
  }

}
</script>

<style></style>
